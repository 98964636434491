<template>
  <b-card class="container text-center py-5 px-1">
    <h1>{{ t("logout") }}</h1>
    <h5>{{ t("readyToLogout") }}</h5>
    <br /><br />
    <router-link to="/"
      ><button class="main">{{ t("noGetBack") }}</button></router-link
    >
    <br />
    <button @click="logout" class="main-outlined mt-3">
      {{ t("yesLogout") }}
    </button>
  </b-card>
</template>

<script>
import cookies from "vue-cookies";

export default {
  methods: {
    async logout() {
      await this.axios.get("user/logout");
      cookies.remove("token");
      cookies.remove("params");
      this.changeurl("/");
      location.reload();
    },
  },
};
</script>

<style scoped>
.container {
  border-radius: 20px;
  margin-top: 100px;
}
</style>